import { MemberJoinControllerService } from '@/__generated__/CommonApi';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import SuccessMessage from '@/components/Form/SuccessMessage';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import InputRadio, { InputRadioGroup } from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import NiceAuthenticationForm from '@/components/ThirdParty/NiceAuthenticationForm';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { emailRegex, mobileRegex } from '@/helpers/ValidationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  BorderLinkButton,
  BorderLinkTab,
  ButtonGroup,
  FormButton,
  FormContainer,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { RouteComponentProps } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const Form = styled.form`
  margin-top: 60px;
  ${breakpoint(`mobile`)} {
    margin-bottom: 100px;
  }
`;

interface FindPwFormData {
  authType: 'P' | 'M';
  name: string;
  email: string;
  mobilePhoneNumber: string;
  emailAuthCode: string;
}

const FindPwPage: FC<RouteComponentProps> = ({ location }) => {
  const popupStore = usePopupStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FindPwFormData>({
    mode: `onChange`,
    defaultValues: {
      authType: `P`,
    },
  });
  const authType = watch(`authType`);
  const email = watch(`email`);

  // 인증코드 보낸 이메일 임시저장
  const [authCodeSentEmail, setAuthCodeSentEmail] = useState<string>(``);
  const isEmailAuthCodeSent =
    !!authCodeSentEmail && authCodeSentEmail === email;
  // 이메일 인증코드 확인 여부
  const [isEmailAuthCodeValid, setIsEmailAuthCodeValid] = useState<boolean>(
    false,
  );
  // 휴대폰 인증코드 확인 여부
  const [isMobileAuthCodeValid, setIsMobileAuthCodeValid] = useState<boolean>(
    false,
  );
  const onSubmit: SubmitHandler<FindPwFormData> = useCallback(
    async (formData) => {
      try {
        const {
          data: _data,
          resultCode,
        } = await MemberJoinControllerService.getFindMyPasswordUsingGet({
          ...formData,
        });

        const data = _data as any;
        if (resultCode === `success`) {
          // 비번찾기 성공시 result로 이동
          navigate(`/auth/find-pw/result`, {
            state: {
              userId: data.findId,
              email: data.email,
              mobilePhoneNumber: data.mobilePhoneNumber,
            },
          });
        } else if (data?.returnMessage) {
          // 메세지 있다면 보여주기
          popupStore.show(data.returnMessage);
        }
      } catch (e) {
        console.error(e.response);
      }
    },
    [popupStore],
  );

  // 이메일 인증코드 발송
  const sendEmailAuthCode = useCallback(async () => {
    // 주소가 있고 에러가 없으면
    if (email && !errors.email) {
      // 발송
      try {
        const {
          resultCode,
          resultMessage,
        } = await MemberJoinControllerService.emailAuthCodeUsingGet({
          emailAuthUseType: `find`,
          reciveMailAddr: email,
        });
        if (resultCode === `success`) {
          setAuthCodeSentEmail(email);
        } else {
          console.error(resultMessage);
        }
      } catch (e) {
        console.error(e.response);
      }
    }
  }, [email, errors.email]);

  // 이메일 인증코드 검증
  const validateEmailAuthCode = useCallback(async () => {
    const params = getValues([`email`, `emailAuthCode`]);
    // 코드가 있으면
    if (params.emailAuthCode) {
      // 확인
      try {
        const {
          resultCode,
        } = await MemberJoinControllerService.emailAuthCheckUsingPost(params);
        if (resultCode === `success`) {
          setIsEmailAuthCodeValid(true);
          clearErrors(`emailAuthCode`);
          // 함수 종료
          return;
        }
      } catch (e) {
        console.error(e.response);
      }
      // 나머지 경우는 실패
      setError(`emailAuthCode`, {
        type: `invalid`,
        message: `인증코드가 올바르지 않습니다.`,
      });
    }
  }, [clearErrors, getValues, setError]);

  return (
    <LayoutWithTitle location={location} title="아이디/비밀번호 찾기">
      <BorderLinkTab>
        <BorderLinkButton>
          <Link to="/auth/find-id">아이디 찾기</Link>
        </BorderLinkButton>
        <BorderLinkButton active>
          <Link to="/auth/find-pw">비밀번호 찾기</Link>
        </BorderLinkButton>
      </BorderLinkTab>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormItem noBorder>
            <p
              css={`
                padding-bottom: 60px;
              `}
            >
              ※ SNS 간편 회원가입 하신 경우, 아이디/비밀번호 찾기 기능을 이용할
              수 없습니다.
            </p>
            <FormGroup>
              <Tit
                size="s4-1"
                css={`
                  padding-bottom: 16px;
                `}
              >
                인증방법 선택
              </Tit>
              <InputRadioGroup full>
                <InputRadio
                  tab
                  name="authType"
                  value="P"
                  label="휴대폰 인증"
                  ref={register}
                />
                <InputRadio
                  tab
                  name="authType"
                  value="M"
                  label="이메일 인증"
                  ref={register}
                />
              </InputRadioGroup>
            </FormGroup>
            <FormGroup>
              <LabelText require>성명</LabelText>
              <InputText
                name="name"
                ref={register({ required: true })}
                placeholder="성명 입력"
              />
              {errors.name && <ErrorMessage>성명을 입력해주세요</ErrorMessage>}
            </FormGroup>
            {authType === `P` && (
              <FormGroup>
                <LabelText require>휴대폰번호</LabelText>
                <Row className="certify" alignItems="flex-start">
                  <div className="col-left">
                    {isMobileAuthCodeValid ? (
                      <FormButton color="grey" disabled>
                        인증완료
                      </FormButton>
                    ) : (
                      <NiceAuthenticationForm
                        onSuccess={(result) => {
                          setValue(`mobilePhoneNumber`, result.mobile);
                          clearErrors(`mobilePhoneNumber`);
                          if (result.resultCode === `success`) {
                            setIsMobileAuthCodeValid(true);
                          }
                        }}
                        renderSubmitButton={(props) => (
                          <FormButton
                            outline
                            disabled={authType !== `P`}
                            color={authType === `P` ? `blue` : `grey`}
                            {...props}
                          >
                            본인인증
                          </FormButton>
                        )}
                      />
                    )}
                  </div>
                  <div className="col-right">
                    <InputText
                      name="mobilePhoneNumber"
                      ref={register({
                        required: {
                          value: true,
                          message: `필수 입력입니다`,
                        },
                        pattern: {
                          value: mobileRegex,
                          message: `올바른 휴대폰번호가 아닙니다`,
                        },
                        validate: {
                          isValidRange: (value) =>
                            (value.substr(0, 3) === `010` &&
                              value.length === 11) ||
                            (value.substr(0, 3) !== `010` &&
                              value.length === 10) ||
                            `휴대폰번호 자릿수를 확인해주세요`,
                        },
                      })}
                      placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
                      readOnly
                    />
                    {errors.mobilePhoneNumber && (
                      <ErrorMessage>
                        {errors.mobilePhoneNumber.message}
                      </ErrorMessage>
                    )}
                  </div>
                </Row>
              </FormGroup>
            )}
            {authType === `M` && (
              <>
                <FormGroup>
                  <LabelText require>이메일</LabelText>
                  <Row alignItems="flex-start">
                    <Col desktop="auto">
                      <InputText
                        name="email"
                        ref={register({
                          required: {
                            value: true,
                            message: `필수 입력입니다`,
                          },
                          pattern: {
                            value: emailRegex,
                            message: `올바르지 않은 이메일주소 입니다`,
                          },
                        })}
                        placeholder="이메일주소 입력"
                      />
                      {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                      )}
                    </Col>
                    <RightCol desktop="none" align="right">
                      <FormButton
                        type="button"
                        outline
                        onClick={sendEmailAuthCode}
                        disabled={
                          !email ||
                          !!errors.email ||
                          (isEmailAuthCodeSent && isEmailAuthCodeValid)
                        }
                      >
                        본인인증
                      </FormButton>
                    </RightCol>
                  </Row>
                  {isEmailAuthCodeSent && (
                    <SuccessMessage>
                      인증코드메일이 발송되었습니다.
                    </SuccessMessage>
                  )}
                </FormGroup>
                {isEmailAuthCodeSent && (
                  <FormGroup>
                    <Row alignItems="flex-start">
                      <Col desktop="auto">
                        <InputText
                          name="emailAuthCode"
                          ref={register({
                            required: {
                              value: true,
                              message: `인증코드를 입력해주세요`,
                            },
                            validate: (value) =>
                              (value &&
                                isEmailAuthCodeSent &&
                                isEmailAuthCodeValid) ||
                              `인증코드 확인이 필요합니다`,
                          })}
                          placeholder="인증코드 입력"
                          readOnly={isEmailAuthCodeValid}
                        />
                      </Col>
                      {authType === `M` && (
                        <RightCol desktop="none">
                          {isEmailAuthCodeValid ? (
                            <FormButton color="grey" disabled>
                              인증완료
                            </FormButton>
                          ) : (
                            <FormButton outline onClick={validateEmailAuthCode}>
                              확인
                            </FormButton>
                          )}
                        </RightCol>
                      )}
                    </Row>
                    {errors.emailAuthCode && (
                      <ErrorMessage>
                        {errors.emailAuthCode.message}
                      </ErrorMessage>
                    )}
                  </FormGroup>
                )}
              </>
            )}
            <ButtonGroup
              css={`
                margin-top: 80px;
              `}
            >
              <Row>
                <Button outline onClick={() => navigate(`/auth/login`)}>
                  로그인 화면으로
                </Button>
                <Button type="submit">비밀번호 찾기</Button>
              </Row>
            </ButtonGroup>
          </FormItem>
        </Form>
      </FormContainer>
    </LayoutWithTitle>
  );
};

export default FindPwPage;
