import FindPwPage from '@/private-pages/find-pw';
import FindPwResultPage from '@/private-pages/find-pw/result';
import { Router } from '@reach/router';
import React, { FC } from 'react';

const FindPwRouter: FC = () => (
  <Router basepath="/auth/find-pw">
    <FindPwPage path="/" />
    <FindPwResultPage path="/result" />
  </Router>
);

export default FindPwRouter;
