import { MemberJoinControllerService } from '@/__generated__/CommonApi';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup, { FormItem } from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import Row from '@/components/Grid/Row';
import InputText from '@/components/Input/InputText';
import { H2, H4 } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import {
  ButtonGroup,
  FormContainer,
  PageTitle,
} from '@/page-blocks/auth/AuthCommon';
import { usePopupStore } from '@/stores/PopupStore';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface ResultLocationState {
  userId: string;
  email: string;
  mobilePhoneNumber: string;
}

interface ResetFormData {
  password: string;
  passwordConfirm: string;
}

const FindPwResultPage: FC<
  RouteComponentProps<{ location: { state: ResultLocationState } }>
> = observer(({ location }) => {
  const popupStore = usePopupStore();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ResetFormData>();
  const userId = location?.state?.userId;
  const email = location?.state?.email;
  const mobilePhoneNumber = location?.state?.mobilePhoneNumber;

  const onSubmit: SubmitHandler<ResetFormData> = useCallback(
    async (formData) => {
      if (!userId) {
        return;
      }
      try {
        const {
          resultCode,
          ...data
        } = await MemberJoinControllerService.setChangePasswordByFindUserInfoUsingGet(
          {
            ...formData,
            userId,
            email,
            mobilePhoneNumber,
          },
        );

        if (resultCode === `success`) {
          // 비번찾기 성공시 로그인 페이지로 이동
          popupStore.show(
            `비밀번호가 변경되었습니다.\n로그인페이지로 이동합니다.`,
            {
              onConfirm: () => navigate(`/auth/login`),
            },
          );
        } else if (data?.returnMessage) {
          // 메세지 있다면 보여주기
          popupStore.show(data.returnMessage);
        }
      } catch (e) {
        console.error(e.response);
      }
    },
    [email, mobilePhoneNumber, popupStore, userId],
  );

  return (
    <LayoutWithTitle location={location} title="아이디/비밀번호 찾기">
      <FormContainer
        css={`
          margin-top: 96px;
          margin-bottom: 120px;
        `}
      >
        <PageTitle>
          <H2>비밀번호를 재설정 해주세요</H2>
        </PageTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem noBorder>
            <FormGroup>
              <H4>
                회원님의 정보가 확인되었습니다. <br />
                새로운 비밀번호를 입력해 주세요.
              </H4>
            </FormGroup>
            <FormGroup>
              <LabelText require>비밀번호</LabelText>
              <InputText
                type="password"
                name="password"
                ref={register({
                  required: true,
                  minLength: {
                    value: 8,
                    message: `8자 이상이어야 합니다`,
                  },
                  maxLength: {
                    value: 16,
                    message: `16자 이하여야 합니다`,
                  },
                  pattern: {
                    value: /(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*?&^()+=-])/,
                    message: `영문 및 숫자,특수문자를 모두 포함해야 합니다`,
                  },
                })}
                placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
              />
              {errors.password && (
                <ErrorMessage>{errors.password.message}</ErrorMessage>
              )}
            </FormGroup>
            <FormGroup>
              <LabelText require>비밀번호 확인</LabelText>
              <InputText
                type="password"
                name="passwordConfirm"
                ref={register({
                  required: true,
                  validate: (value) =>
                    value === getValues(`password`) ||
                    `비밀번호가 일치하지 않습니다`,
                })}
                placeholder="8-16자의 영문 및 숫자,특수문자를 모두 포함"
              />
              {errors.passwordConfirm && (
                <ErrorMessage>{errors.passwordConfirm.message}</ErrorMessage>
              )}
            </FormGroup>
            <ButtonGroup
              css={`
                margin-top: 80px;
              `}
            >
              <Row>
                <Button outline onClick={() => navigate(`/auth/login`)}>
                  취소
                </Button>
                <Button type="submit">저장</Button>
              </Row>
            </ButtonGroup>
          </FormItem>
        </form>
      </FormContainer>
    </LayoutWithTitle>
  );
});

export default FindPwResultPage;
